import React from "react";
import EdynLeaves from "./icons/EdynLeaves";


const CarerCV = ({
    options: {
        profileImage,
		name,
        experienceYears,
		languages,
        education,
		supports,
        interests
}}) => {
    return (
        <div className="carer-cv">
           <div className="carer-cv__top">
                {profileImage}
                <div className="carer-cv__top-description">
                    <div className="carer-cv__top-description-name">{name}</div>
                    <div className="carer-cv__top-description-experience">{experienceYears} {experienceYears <= 2 ? "year" : "years"} experience</div>
                </div>
            </div>
            <div className="carer-cv__bottom">
                <div className="carer-cv__bottom-element">
                    <div className="carer-cv__bottom-title">
                        Languages
                    </div>
                    <ul className="carer-cv__bottom-list">
                        {languages.map(language => <li>{language}</li>)}
                    </ul>
                </div>
                <div className="carer-cv__bottom-element">
                    <div className="carer-cv__bottom-title">
                        Education
                    </div>
                    <ul className="carer-cv__bottom-list">
                        {education.map(education => <li>{education}</li>)}
                    </ul>
                </div>
                <div className="carer-cv__bottom-element">
                    <div className="carer-cv__bottom-title">
                        Support
                    </div>
                    <ul className="carer-cv__bottom-list">
                        {supports.map(support => <li>{support}</li>)}
                    </ul>
                </div>
                <div className="carer-cv__bottom-element">
                    <div className="carer-cv__bottom-title">
                        Interests
                    </div>
                    <ul className="carer-cv__bottom-list">
                        {interests.map(interest => <li>{interest}</li>)}
                    </ul>
                </div>
            </div>
        </div>
    )
}

CarerCV.EdynTraining = () => 
    <>
        <EdynLeaves className="carer-cv__leaves-icon" />
        Edyn Training
    </>




export default CarerCV;