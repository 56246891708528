import React, {useState} from "react";
import { Helmet } from "react-helmet";
import {LinkWithUTM as Link} from '../components/LinkWithUTM'
import { StaticImage } from "gatsby-plugin-image"
import Header from "../components/Header";
import Footer from "../components/Footer";
import { PostcodeCTA } from '../components/PostcodeCTA/PostcodeCTA';
import TpStars from "../components/Trustpilot/TpStars"
import GreenCheckMark from "../components/icons/GreenCheckMark";
import CarerCV from "../components/CarerCV";
import EdynLeaves from "../components/icons/EdynLeaves";
import GroupTech from "../components/icons/Approach/GroupTech";
import CalendarDay from "../components/icons/Approach/CalendarDay";
import PieChart from "../components/icons/Approach/PieChart";
import TextDoc from '../components/icons/Approach/TextDoc';
import { UK, FR, ES, CZ, LK } from "../components/flags/flags"

import "../../scss/main.scss";


export default function Home() {
	const [imageSelected, setImageSelected] = useState(0);
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Our Carers</title>
				<meta property="og:title" content="Edyn Care | Our Carers"/>
				<meta property="og:description" content="High quality care in the comfort of your own home."/>
				<meta property="og:image" content="https://www.edyn.care/assets/marketing-site/images/open-graph-img.png"/>
				<meta property="og:url" content="https://www.edyn.care/our-carers" />
			</Helmet>
			{/*   ========== Nav ==========  */}
			<div className="container our-carers">
				<Header />
				<section className="oc-above-the-fold-section">
					<div className="oc-above-the-fold__left">
						<h1 className="oc-above-the-fold__title">Brilliant professional carers to help you flourish</h1>
						{/* MEET ONE OF OUR CARERS BUTTON */}
						{/*<Link className="oc-above-the-fold__meet-button" to="/">
							<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="12.9141" cy="12.5" r="12" fill="white"/>
								<path fillRule="evenodd" clipRule="evenodd" d="M16.4141 12.5L10.4141 16V9L16.4141 12.5Z" fill="#112C2C"/>
							</svg>
							Meet one of our carers
						</Link>*/}
						<PostcodeCTA className="oc-above-the-fold__postcode-cta"/>
					</div>
					<div className="oc-above-the-fold__right">
						<StaticImage src="../../static/assets/marketing-site/images/oc-header.png" />
						{/* <div className="oc-above-the-fold__tp-container">
							<TpStars trustScore={5} className="oc-above-the-fold__tp-stars"/>
							<div className="oc-above-the-fold__tp-quote">
								“ I don't know how they do this but Edyn Care seem to attract a much higher quality of carers ”
							</div>
							<div className="oc-above-the-fold__tp-rated">
								Rated excellent on
								<StaticImage src="../../static/assets/marketing-site/images/trustpilot-icon.png" placeholder="blurred" objectFit="contain"/>
							</div>
						</div> */}
					</div>
				</section>
    			<section className="oc-reliable-section">
					<div className="oc-reliable__description-container">
						<h3 className="oc-reliable__description-title">Reliable carers in your area. Trained by us.</h3>
						<div className="oc-reliable__description">
							<p>Our professional carers provide both care and companionship to clients. We have a stringent recruitment and training process with only 5% of applicants joining the team. Our ethos is simple: better treated carers leads to better care.</p>
							<p>They are the missing piece in your care journey, allowing you to relax in the comfort that everything is in place.</p>
							<p>Our recruitment and training team do all the necessary safety checks and training- giving you peace of mind.</p>
						</div>
					</div>
					<div className="oc-reliable__points-card">
						<CheckMarkElement title="First-class training programme" />
						<CheckMarkElement title="DBS checked" />
						<CheckMarkElement title="Rigorous safe checks" />
						<CheckMarkElement title="24/7 access to our care management team" />
						<CheckMarkElement title="Excellent spoken and written English" />
						<CheckMarkElement title="Public Liability Insurance included" />
						<CheckMarkElement title="Ability prepare and cook nutritious meals" />
						<CheckMarkElement title="COVID-19 trained" />
						<CheckMarkElement title="Policies + procedures overseen by edyn" />
						<StaticImage src="../../static/assets/marketing-site/images/carer-1.png" placeholder="blurred" objectFit="contain" height={150}/>
					</div>
				</section>
				<section className="oc-your-care-team-section">
					<StaticImage className="oc-your-care-team-section__img" src="../../static/assets/marketing-site/images/your-care-team.png" placeholder="blurred" objectFit="contain" height={78}/>
					<h3>Your care team:</h3>
					<div className="oc-your-care-team-section__point">
						<GreenCheckMark />
						Carers
					</div>
					<div className="oc-your-care-team-section__point">
						<GreenCheckMark />
						Care coordinator
					</div>
					<div className="oc-your-care-team-section__point">
						<GreenCheckMark />
						Field Care supervisor
					</div>
				</section>
				<section className="oc-we-care-section">
					<h3 className="oc-we-care__title">We care for our carers</h3>
					<div className="oc-we-care__percentages-container">
						<div className="oc-we-care__percentage-card">
							<svg width="193" height="158" viewBox="0 0 193 158" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M92.2958 92.1233C2.72265 171.797 -99.6351 158.506 -120.768 154.117L-149.734 127.146L116.17 -174.69C200.658 -109.76 192.818 -101.533 190.975 -92.6997C188.671 -81.6586 204.262 -7.46868 92.2958 92.1233Z" fill="#F8F8EF"/>
							</svg>
							<div className="oc-we-care__percentage-card-value">12</div>
							<p>is the minimum number of months of experience required</p>
						</div>
						<div className="oc-we-care__percentage-card">
							<svg width="350" height="93" viewBox="0 0 350 93" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M71.1836 2.45025C-142.816 -17.5497 -168.816 104.45 -200.816 126.45L-114.816 238.45L349.184 126.45C327.85 91.7836 242.384 18.4503 71.1836 2.45025Z" fill="#F8F8EF"/>
							</svg>
							<div className="oc-we-care__percentage-card-value">5%</div>
							<p>of those who apply pass our recruitment process</p>
						</div>
						<div className="oc-we-care__percentage-card">
							<svg width="412" height="126" viewBox="0 0 412 126" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M241.453 2.30938C27.4531 -17.6906 1.45312 104.309 -30.5469 126.309L55.4531 238.309L519.453 126.309C498.12 91.6427 412.653 18.3094 241.453 2.30938Z" fill="#F8F8EF"/>
							</svg>
							<div className="oc-we-care__percentage-card-value">5</div>
							<p>is the average number of years of experience</p>
						</div>
					</div>
					<div className="oc-we-care__cv-container">
						<CarerCV 
							options = {{
								profileImage: <StaticImage src="../../static/assets/marketing-site/images/olivia.png" placeholder="blurred" objectFit="contain"/>,
								name: "Olivia",
								experienceYears: 4,
								languages: [
									<div><UK />&nbsp;&nbsp;English</div>,
									<div><FR />&nbsp;&nbsp;French</div>
								],
								education: [<CarerCV.EdynTraining />],
								supports : ["Medication", "Housekeeping"],
								interests: ["Dancing", "Sport", "Cooking"]
							}}
						/>
						<CarerCV 
							options = {{
								profileImage: <StaticImage src="../../static/assets/marketing-site/images/ayaan.png" placeholder="blurred" objectFit="contain"/>,
								name: "Ayaan",
								experienceYears: 5,
								languages: [
									<div><UK />&nbsp;&nbsp;English</div>,
									<div><LK />&nbsp;&nbsp;Sinhalese</div>
								],
								education: ["NVQ/QFC Level 2"],
								supports : ["Companionship", "Cooking"],
								interests: ["Gardening", "Music"]
							}}
						/>
						<CarerCV 
							options = {{
								profileImage: <StaticImage src="../../static/assets/marketing-site/images/adela.png" placeholder="blurred" objectFit="contain"/>,
								name: "Adela",
								experienceYears: 3,
								languages: [
									<div><UK />&nbsp;&nbsp;English</div>,
									<div><CZ />&nbsp;&nbsp;Czech</div>
								],
								education: [<CarerCV.EdynTraining />],
								supports : ["Medication", "Staying active"],
								interests: ["Exercise", "Films", "Politics"]
							}}
						/>
						<CarerCV 
							options = {{
								profileImage: <StaticImage src="../../static/assets/marketing-site/images/frederico.png" placeholder="blurred" objectFit="contain"/>,
								name: "Frederico",
								experienceYears: 7,
								languages: [
									<div><UK />&nbsp;&nbsp;English</div>,
									<div><ES />&nbsp;&nbsp;Spanish</div>
								],
								education: ["NVQ/QFC Level 3"],
								supports : ["Cooking", "Compashionship"],
								interests: ["Travelling", "Sport", "Cooking"]
							}}
						/>
					</div>
				</section>
				<section className="oc-aljona-quote-section">
					<svg className="oc-aljona-quote-section__background" width="1023" height="136" viewBox="0 0 1023 136" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M331.061 -670.972C404.355 -720.159 488.129 -761.948 583.568 -762.166C679.007 -762.384 762.562 -720.452 836.279 -671.619C909.995 -622.786 989.685 -566.166 1019.08 -483.215C1048.33 -400.782 1021.17 -312.586 993.353 -233.995C965.535 -155.404 910.561 -95.1465 833.967 -44.0653C757.005 7.53368 676.382 29.5999 585.443 29.8315C494.503 30.0631 414.794 7.44516 337.443 -43.7996C260.638 -94.7241 178.767 -146.146 150.772 -224.648C122.777 -303.15 158.912 -387.797 187.882 -470.196C216.777 -553.147 257.713 -621.785 331.061 -670.972Z" stroke="#7DDE8B"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M315.701 -692.708C393.272 -744.793 481.438 -788.982 582.322 -789.166C683.206 -789.35 776.081 -751.174 853.951 -699.375C931.822 -647.577 1013.01 -582.204 1044.1 -494.208C1075.08 -406.635 1061.39 -307.549 1031.91 -224.064C1002.43 -140.579 927.318 -81.8466 846.188 -27.6049C764.765 27.0531 679.919 48.6188 583.805 48.8303C487.692 49.0419 394.878 38.0065 313.149 -16.3717C231.855 -70.4838 149.922 -134.887 120.302 -218.297C90.6822 -301.707 136.116 -391.887 166.893 -479.446C197.574 -567.483 238.136 -640.622 315.701 -692.708Z" stroke="#7DDE8B"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M308.316 -713.944C390.319 -768.743 474.637 -822.03 581.235 -822.166C687.833 -822.303 782.631 -782.196 864.852 -727.615C947.073 -673.034 1037.24 -608.672 1070.11 -515.939C1102.91 -423.527 1085.29 -319.276 1054.07 -231.216C1022.86 -143.156 947.291 -78.2145 861.42 -21.011C775.324 36.499 683.87 42.6907 582.325 42.827C480.779 42.9632 385.982 41.4237 299.655 -15.8751C213.654 -72.9696 119.415 -135.643 88.093 -223.662C56.7707 -311.681 110.979 -407.574 143.622 -500C176.204 -592.726 226.32 -659.152 308.316 -713.944Z" stroke="#7DDE8B"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M295.653 -725.646C382.086 -783.342 472 -835.098 584.299 -835.167C696.598 -835.235 796.247 -795.753 882.83 -738.234C969.413 -680.715 1078.15 -617.155 1112.8 -519.445C1147.41 -421.932 1104.91 -310.918 1071.97 -218.042C1039.03 -125.167 969.256 -53.9555 878.654 6.37262C787.902 66.9122 692 81.7367 585.049 81.8322C478.097 81.9277 375.771 75.4291 284.869 15.0055C194.185 -45.2613 89.1278 -112.143 56.1227 -205.018C23.1176 -297.894 75.878 -405.287 110.368 -502.806C144.79 -600.448 209.213 -667.977 295.653 -725.646Z" stroke="#7DDE8B"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M268.818 -754.884C359.63 -815.348 462.949 -864.126 580.871 -864.167C698.794 -864.208 802.869 -816.445 893.756 -756.023C984.643 -695.6 1081.82 -624.407 1118.24 -521.798C1154.63 -419.292 1119.77 -310.153 1085.11 -212.522C1050.46 -114.89 976.36 -48.4275 881.073 14.9536C785.71 78.437 693.556 110.792 581.239 110.833C468.923 110.874 359.862 99.9594 264.397 36.5238C169.075 -26.7891 69.2511 -101.896 34.5664 -199.514C-0.118358 -297.132 56.0161 -404.533 92.3629 -507.032C128.682 -609.674 178.005 -694.414 268.818 -754.884Z" stroke="#7DDE8B"/>
						<path fillRule="evenodd" clipRule="evenodd" d="M581.779 -885.167C458.06 -885.167 354.01 -833.609 258.72 -770.228C163.431 -706.847 102.386 -624.871 64.1663 -517.223C25.9468 -409.574 -22.9014 -296.84 13.4978 -194.345C49.897 -91.8501 152.768 -15.4995 252.796 51.0215C352.824 117.543 463.971 134.833 581.752 134.833C699.533 134.833 802.574 107.392 902.636 40.8574C1002.7 -25.6772 1078.65 -102.239 1115.05 -204.748C1151.44 -307.257 1204.69 -429.541 1166.47 -537.182C1128.25 -644.824 1005.04 -713.113 909.754 -776.474C814.464 -839.834 705.464 -885.167 581.779 -885.167Z" stroke="#7DDE8B"/>
					</svg>
					<div className="oc-aljona-quote__text">
						<span className="oc-aljona-quote__text-quotes">“ </span>
						For too long, carers have been treated poorly by inefficient agencies. We take our recruitment and development of our carers very seriously. They are the lifeblood of our company which is why we spend on vetting, training, and developing them.<span className="oc-aljona-quote__text-quotes">”</span>
					</div>
					<div className="oc-aljona-quote__profile">
						<StaticImage className="oc-aljona-quote__profile-img" src="../../static/assets/marketing-site/images/team/oliver.png" placeholder="blurred" objectFit="contain"/>
						<div className="oc-aljona-quote__profile-text">
							<div>Oliver Ross</div>
							<div>COO</div>
							{/* <div>Care Manager</div> */}
						</div>
					</div>
				</section>
				<section className="oc-join-team">
                    <div className="oc-join-team__header">
                        <EdynLeaves className="oc-join-team__leaves-icon"/>
                        <h1 className="oc-join-team__header__title">Our carer recruitment process</h1>
                        <p className="oc-join-team__header__description">Here's how recruitement with Edyn works:</p>
                    </div>
                    <div className="oc-join-team__panels">
                        <div className="oc-join-team__panel">
                            <StaticImage className="oc-join-team__panel__bg" src="../../static/assets/marketing-site/images/bac-rectangle-1.png" objectFit="fill" placeholder="tracedSVG"/>
                            <StaticImage className="oc-join-team__panel__img" src="../../static/assets/marketing-site/images/bac-join-1.png" objectFit="contain" placeholder="blurred"/>
                            <div className="oc-join-team__panel__description">
                                <div className="oc-join-team__panel__description__number-wrapper">
                                    <div className="oc-join-team__panel__description__number">1</div>
                                </div>
                                <h3>Application and scenario testing</h3>
								<p>Our carers complete an application and are given some scenario tests which is a mix of video clips, written questions and audio clips which will assess how well they deal with certain situations and the English language.</p>
                            </div>
							<div className="oc-join-team__panel__description-extra"><span className="oc-join-team__panel__description-extra-percentage">50%</span>pass rate</div>
						</div>
                        <div className="oc-join-team__panel">
                            <StaticImage className="oc-join-team__panel__bg" src="../../static/assets/marketing-site/images/bac-rectangle-2.png" objectFit="fill" placeholder="tracedSVG"/>
                            <StaticImage className="oc-join-team__panel__img" src="../../static/assets/marketing-site/images/bac-join-2.png" objectFit="contain" placeholder="blurred"/>
                            <div className="oc-join-team__panel__description">
                                <div className="oc-join-team__panel__description__number-wrapper">
                                    <div className="oc-join-team__panel__description__number">2</div>
                                </div>
                                <h3>Interviews</h3>
								<p>Our carers complete a 1:1 interview with our recruitment and training specialist in which we review their previous experience, assess whether they would be a cultural fit and get a better understanding of their personal goals.</p>
                            </div>
							<div className="oc-join-team__panel__description-extra"><span className="oc-join-team__panel__description-extra-percentage">20%</span>pass rate</div>
                        </div>
                        <div className="oc-join-team__panel">
                            <StaticImage className="oc-join-team__panel__bg" src="../../static/assets/marketing-site/images/bac-rectangle-3.png" objectFit="fill" placeholder="tracedSVG"/>
                            <div className="oc-join-team__panel__points">
                                <div className="oc-join-team__panel__points_point">
                                    <GreenCheckMark />
                                    <div>Moving & handling</div>
                                </div>
								<div className="oc-join-team__panel__points_point">
                                    <GreenCheckMark />
                                    <div>Medication</div>
                                </div>
								<div className="oc-join-team__panel__points_point">
                                    <GreenCheckMark />
                                    <div>First aid</div>
                                </div>
								<div className="oc-join-team__panel__points_point">
                                    <GreenCheckMark />
                                    <div>Challenging behaviour & Dementia</div>
                                </div>
								<div className="oc-join-team__panel__points_point">
                                    <GreenCheckMark />
                                    <div>Safeguarding</div>
                                </div>
								<div className="oc-join-team__panel__points_point">
                                    <GreenCheckMark />
                                    <div>Infection control</div>
                                </div>
                                <div className="oc-join-team__panel__points_point">
                                    <GreenCheckMark />
                                    <div>Further 10 modules</div>
                                </div>
                                <StaticImage className="oc-join-team__panel__points_bg-texture" src="../../static/assets/marketing-site/images/bac-texture.png" objectFit="contain" placeholder="blurred"/>
							</div>
                            <div className="oc-join-team__panel__description">
                                <div className="oc-join-team__panel__description__number-wrapper">
                                    <div className="oc-join-team__panel__description__number">3</div>
                                </div>
                                <h3>In-house training</h3>
								<p>The training at edyn.care is second to none. All our carers are required to attend our induction training in London. We are also offering online training options during the pandemic.</p>
                            </div>
							<div className="oc-join-team__panel__description-extra">
								<GreenCheckMark style={{
									minWidth: 37,
									minHeight: 37,
								}}/>
								Start work
							</div>
                        </div>
                    </div>
                </section> 
				<section className="bac-qa">
                    <h1 className="bac-qa__title">Development and Quality Assurance</h1>
                    <div className="bac-qa__card-list">
                        <div className="bac-qa__card">
                            <div className="bac-qa__card-title--mobile">
                                <h3>Development</h3>
                            </div>
                            <StaticImage src="../../static/assets/marketing-site/images/bac-qa-1.png" placeholder="blurred" objectFit="contain"/>
                            <div className="bac-qa__card-description">
                                <div>
									<h3 className="bac-qa__card-description-title">Development</h3>
                                	<p className="bac-qa__card-description-text">We support training in Health and Social Care to Diploma Level as well as the opportunity for advanced training towards specialisation in a specific or complex condition. Our care management team completes informative webinars to keep our carers up-to-date on insights and developments in the industry.</p>
								</div>
                                <ul className="bac-qa__card-description-points">
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Funded Health and Social Care Diplomas</div>
                                    </li>
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Online training</div>
                                    </li>
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Webinars</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bac-qa__card">
                            <div className="bac-qa__card-description">
                                	<div>
										<h3 className="bac-qa__card-description-title">Quality Assurance</h3>
                                		<p className="bac-qa__card-description-text">Our Field Care Supervisors work with you to maintain the quality of our service. They are here to support you and your career development through 1:1 mentorship. All of them have been professional carers themselves, so understand the role. They help all of our professional carers through:</p>
									</div>
									<ul className="bac-qa__card-description-points">
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Unannounced spot checks</div>
                                    </li>
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Supervisions</div>
                                    </li>
                                    <li>
                                        <div className="bac-qa__card-bullet">&#x2022;</div>
                                        <div>Appraisals</div>
                                    </li>
                                </ul>
                            </div>
                            <div className="bac-qa__carer-card">
                                <div className="bac-qa__carer-profile">
                                    <StaticImage src="../../static/assets/marketing-site/images/team/Issop.png" placeholder="blurred" objectFit="contain"/>
                                    <div>
                                        <p className="bac-qa__carer-profile-name">Issop Koheeallee</p>
                                        <p className="bac-qa__carer-profile-position">Field Care Supervisor</p>
                                    </div>
                                </div>
                                <p className="bac-qa__carer-quote">
                                    <div className="bac-qa__carer-quote-quotes">“</div>
                                    <div>
                                        I really enjoy getting to know our professional carers and care recipients and ensuring that our quality assurance processes are maintained. I spend a lot of the time in our client’s home completing quality assurance checks.
                                        <span className="bac-qa__carer-quote-quotes">”</span>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
				<section className="oc-learn-more-button-section">
					<Link to="/approach/">
						<button className="btn-primary oc-learn-more-button-section">Learn more about our process</button>
					</Link>
				</section>
				<section className="oc-carers-safe-section">
					<div className="oc-carers-safe-description">
						<h3>Are carers safe during COVID-19?</h3>
						<p>Yes. Our carers complete COVID-19 training and aresupplied with personal protective equipment. We also complete weekly lateral flow tests and have rigorous safety procedures.</p>
						<p>You can find more detail on:</p>
						<Link to="/blog/our-covid-19-response/">Our covid-19 strategy</Link>
						<Link to="https://docs.google.com/document/d/194TZvyQ3j7n5lyuCMJzjzN6cltAsaKUkbFkdhfSCKG4">Our testing policy and procedure</Link>
					</div>
					<StaticImage src="../../static/assets/marketing-site/images/our-carers-texture.png" placeholder="blurred" objectFit="contain"/>
				</section>
				<section className="bac-values">
                    <h1 className="bac-values__title">Our carer values</h1>
                    <p className="bac_values__subtitle">Our philosophy to care sits at the centre of Edyn. Our carers are:</p>
                    <div className="bac-values__panels">
                        <div className="value_panel">
                            <StaticImage src="../../static/assets/marketing-site/images/value-1.png" placeholder="blurred" objectFit="contain"/>
                            <h4 className="value_panel__title">Attentive</h4>
                            <p className="value_panel__description">You make wise decisions, and identify root causes, in sometimes stressful situations. This requires an ability to use your common sense and think strategically.</p>
                        </div>
                        <div className="value_panel">
                            <StaticImage src="../../static/assets/marketing-site/images/value-2.png" placeholder="blurred" objectFit="contain"/>
                            <h4 className="value_panel__title">Passionate</h4>
                            <p className="value_panel__description">Willingness to work on something exciting to improve the care industry.</p>
                        </div>
                        <div className="value_panel">
                            <StaticImage src="../../static/assets/marketing-site/images/value-3.png" placeholder="blurred" objectFit="contain"/>
                            <h4 className="value_panel__title">Thoughtful</h4>
                            <p className="value_panel__description">You have great interpersonal skills and an understanding of others sensitivities. Going the extra mile.</p>
                        </div>
                        <div className="value_panel">
                            <StaticImage src="../../static/assets/marketing-site/images/value-4.png" placeholder="blurred" objectFit="contain"/>
                            <h4 className="value_panel__title">Honest</h4>
                            <p className="value_panel__description">You know your limitations and do not conduct work that you are unable to properly administer. When you make mistakes you are honest and transparent.</p>
                        </div>
                    </div>
                </section>
				<section>
						<div className="approach-tech">
							<div className="approach-tech__description">
								<h1 className="approach-tech__description-title">Technology and carers</h1>
								<p className="approach-tech__description-text">Our technology enables us to get real-time updates from our carers, so our care management team can communicate effectively with what is happening in the home. This enables us to make necessary interventions and ensure quality assurance.</p>
								<div className="approach-tech__categories">
									{[
										<>
											<GroupTech className="gatsby-image-wrapper" />
											<p className="secondary">Your care team</p>
										</>,
										<>
											<CalendarDay className="gatsby-image-wrapper" />
											<p className="secondary">Visit summary</p>
										</>,
										<>
											<PieChart className="gatsby-image-wrapper" />
											<p className="secondary">Wellness report</p>
										</>,
										<>
											<TextDoc className="gatsby-image-wrapper" />
											<p className="secondary">Regular care statements</p>
										</>

									].map((element, index) =>
										<div
											className={`approach-tech-topic ${imageSelected === index ? "green" : ""}`}
											onClick={() => setImageSelected(index)}
										>
											{element}
										</div>
									)
									}
								</div>
							</div>
							{[
								<StaticImage className="approach-img" placeholder="blurred" src="../../static/assets/marketing-site/images/tech-image-00.png" />,
								<StaticImage className="approach-img" placeholder="blurred" src="../../static/assets/marketing-site/images/tech-image-01.png" />,
								<StaticImage className="approach-img" placeholder="blurred" src="../../static/assets/marketing-site/images/tech-image-02.png" />,
								<StaticImage className="approach-img" placeholder="blurred" src="../../static/assets/marketing-site/images/tech-image-03.png" />
							].map((element, index) =>
								index === imageSelected ? <div>{element}</div> : null
							)}
						</div>
					</section>
					<section className="bac-cta bac-cta--full-height">
                    <div className="bac-cta__card">
							<StaticImage src="../../static/assets/marketing-site/images/we-care.png" placeholder="blurred" objectFit="contain" />
							<div className="bac-cta__description">
								<h1 className="bac-cta__description-title">Have you got the skills to become a professional carer?</h1>
								<p className="bac-cta__description-text">
									Fill out our application form and if you're successful, we’ll be in touch.
								</p>
								<Link to="https://www.app.edyn.care/join">
									<button className="btn-orange">Apply here</button>
								</Link>
							</div>
						</div>
					</section>
				<Footer />

			</div>
			{/*  end of container */}

		</>
	);
}


const CheckMarkElement = ({title = "title"}) => 
	<div className="check-mark-element">
		<GreenCheckMark />
		{title}
	</div>